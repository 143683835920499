<template>
  <div class="hae-first-screening">
    <img
      alt="title logo"
      src="../../assets/hae/screening1.svg"
      class="topset-logo"
    />
    <ScreeningHeadline />
    <ScreeningSelect
      v-for="q in questions"
      v-bind:question="q"
      v-bind:key="'screening' + q.id"
    />
    <p class="next-note">
      次へ進むにはすべての設問に答えてください
    </p>
    <div v-if="isAnswerComplete">
      <router-link
        :to="{ name: fetchNextViewName }"
        class="lineless"
        @click.native="sendGa('click', 'hae', 'next-from-first-screening')"
      >
        <CommonButton text="次へ" styleType="active" />
      </router-link>
    </div>
    <div v-else>
      <CommonButton text="次へ" styleType="inactive" />
    </div>
    <br />
    <QuoteOrigin />
  </div>
</template>

<script>
import ScreeningSelect from "@/components/hae/ScreeningSelect.vue";
import CommonButton from "@/components/hae/CommonButton.vue";
import QuoteOrigin from "@/components/hae/QuoteOrigin.vue";
import ScreeningHeadline from "@/components/hae/ScreeningHeadline.vue";
import rootStore from "@/store";
import { questions } from "../../data/hae";
const state = rootStore.state;
function targetAnswers() {
  return state.hae.screeningAnswers.slice(0, 3);
}
function isContinuable() {
  // 信じられないかもしれないがjsは配列の比較をしようとするとこうなる
  return JSON.stringify(targetAnswers()) !== JSON.stringify([1, 1, 1]);
}

export default {
  name: "HaeFirstScreening",
  components: {
    ScreeningSelect,
    CommonButton,
    ScreeningHeadline,
    QuoteOrigin
  },
  head: {
    title: {
      inner: "かんたんHAEセルフチェック"
    }
  },
  data: function() {
    return {
      questions: questions().slice(0, 3)
    };
  },
  computed: {
    isAnswerComplete() {
      return targetAnswers().every(el => typeof el === "number");
    },
    fetchNextViewName() {
      return isContinuable() ? "HaeSecondScreening" : "HaeFirstResult";
    }
  },
  beforeCreate: function() {
    this.axios
      .get(process.env.VUE_APP_API_BASE_URL + "v2/api/self_checker/user_info", {
        withCredentials: true
      })
      .then(res => {
        this.$cookies.set("patient_id", res.data.patientId, "24h");
        this.$store.commit("hae/changeUserInfo", {
          patientId: res.data.patientId,
          userStatus: res.data.userStatus
        });
      });
  }
};
</script>

<style scoped>
@import "../../assets/style/hae.css";
</style>
